import React from "react"

import { withError } from "./withError"
import { RichText } from "../RichText/RichText"
import { CollectionGrid } from "../Sections/CollectionGrid/CollectionGrid"
import { FeaturedCollections, StyledContainer, StyledH5 } from "./ErrorStyles"

interface Props {
  content: any
}

export const Error = withError(({ content }: Props) => (
  <>
    <StyledContainer>
      <StyledH5 as={`h1`}>{content.title}</StyledH5>
      <RichText content={content.message} />
    </StyledContainer>

    <FeaturedCollections>
      <CollectionGrid handles={content.featuredCollections.map(item => item.shopifyHandle)} />
    </FeaturedCollections>
  </>
))
